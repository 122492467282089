import React from 'react';

export default function FeatureCheat() {
    return (
        <div >
            <h3 className = "langCng" lang="en">Cheating</h3>
            <p className = "langCng" lang="en">You can get any number you like by tapping a specific place.</p>

            <h3 className="langCng" lang="ja">いかさま</h3>
            <p className="langCng" lang="ja">特定の場所をタップすると、好きな数字を出すことができます</p>

            <h3 className = "langCng" lang="ru">Мошенничество</h3>
            <p className = "langCng" lang="ru">Вы можете получить любой понравившийся номер, нажав на определенное место.</p>

            <h3 className = "langCng" lang="fr">Tricherie</h3>
            <p className = "langCng" lang="fr">Vous pouvez obtenir n'importe quel numéro en appuyant sur un endroit spécifique.</p>

            <h3 className = "langCng" lang="de">Schummeln</h3>
            <p className = "langCng" lang="de">Sie können eine beliebige Nummer erhalten, indem Sie auf einen bestimmten Ort tippen.</p>

            <h3 className = "langCng" lang="it">Imbrogliare</h3>
            <p className = "langCng" lang="it">Puoi ottenere qualsiasi numero che ti piace toccando un luogo specifico.</p>

            <h3 className = "langCng" lang="zh">作弊</h3>
            <p className = "langCng" lang="zh">您可以通过点击特定位置来获取您喜欢的任何号码</p>

            <h3 className = "langCng" lang="ko">사기</h3>
            <p className = "langCng" lang="ko">특정 위치를 터치하면 원하는 숫자를 낼 수 있습니다</p>

            <h3 className = "langCng" lang="fi">Pettäminen</h3>
            <p className = "langCng" lang="fi">Voit saada haluamasi numeron napauttamalla tiettyä paikkaa.</p>

            <h3 className = "langCng" lang="es">Infiel</h3>
            <p className = "langCng" lang="es">Puede obtener el número que desee tocando un lugar específico.</p>

            <h3 className = "langCng" lang="tr">Hile</h3>
            <p className = "langCng" lang="tr">Belirli bir yere dokunarak istediğiniz numarayı alabilirsiniz.</p>

            <h3 className = "langCng" lang="ms">Menipu</h3>
            <p className = "langCng" lang="ms">Anda boleh mendapatkan nombor yang anda suka dengan mengetuk tempat tertentu.</p>

            <h3 className = "langCng" lang="ta">ஏமாற்றுதல்</h3>
            <p className = "langCng" lang="ta">ஒரு குறிப்பிட்ட இடத்தை தட்டுவதன் மூலம் நீங்கள் விரும்பும் எந்த எண்ணையும் பெறலாம்</p>

            <h3 className = "langCng" lang="id">selingkuh</h3>
            <p className = "langCng" lang="id">Anda bisa mendapatkan nomor apa pun yang Anda suka dengan mengetuk tempat tertentu.</p>

            <h3 className = "langCng" lang="pt">Trapaça</h3>
            <p className = "langCng" lang="pt">Você pode obter qualquer número que desejar tocando em um lugar específico.</p>

            <h3 className = "langCng" lang="hi">धोखा</h3>
            <p className = "langCng" lang="hi">आप किसी विशिष्ट स्थान पर टैप करके अपनी पसंद का कोई भी नंबर प्राप्त कर सकते हैं।</p>

            <h3 className = "langCng" lang="ar">الغش</h3>
            <p className = "langCng" lang="ar">يمكنك الحصول على أي رقم تريده من خلال النقر على مكان معين.</p>

        </div>
    );
  }